import { render, staticRenderFns } from "./RecruitBohum.vue?vue&type=template&id=3f99eda4&scoped=true&"
import script from "./RecruitBohum.vue?vue&type=script&lang=js&"
export * from "./RecruitBohum.vue?vue&type=script&lang=js&"
import style0 from "./RecruitBohum.vue?vue&type=style&index=0&id=3f99eda4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f99eda4",
  null
  
)

export default component.exports